import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "frequently-asked-questions-️"
    }}>{`Frequently Asked Questions 🙋‍♂️`}</h1>
    <p>{`Some of our most common questions. Can't find what you need here? Email us at `}<a parentName="p" {...{
        "href": "mailto:support@canyou.com.au"
      }}>{`support@canyou.com.au`}</a></p>
    <hr></hr>
    <h2 {...{
      "id": "security"
    }}>{`Security`}</h2>
    <p>{`Canyou ensures the security of your data by:`}</p>
    <ul>
      <li parentName="ul">{`Only storing sensitive information in Australia`}</li>
      <li parentName="ul">{`Ensuring that access to these systems is highly restricted and controlled`}</li>
      <li parentName="ul">{`Using strong encryption to protect data communications`}</li>
      <li parentName="ul">{`Requiring encryption between your device and our services`}</li>
      <li parentName="ul">{`Limiting access to your data only to staff who have a need to access your information to perform a specific task or function`}</li>
      <li parentName="ul">{`Encrypting all data at rest`}</li>
    </ul>
    <p><strong parentName="p">{`What regulations does Canyou adhere to?`}</strong></p>
    <p>{`Canyou adheres to the regulations produced by `}<a parentName="p" {...{
        "href": "https://www.dspanz.org/industry-standards/addon-security-standard/"
      }}>{`Digital Service Providers
Australia New Zealand`}</a>{` in
relation to addon security as well as any requirements imposed by our partners such as Xero.`}</p>
    <p>{`Our Terms and Conditions can be found `}<a parentName="p" {...{
        "href": "/terms-and-conditions"
      }}>{`here`}</a>{`.`}</p>
    <p><strong parentName="p">{`Passwords and Authentication`}</strong></p>
    <p>{`Canyou does not store or use passwords to access our services. This
removes the most common attack method used to gain access to users accounts
such as reused passwords or frequently occurring passwords.`}</p>
    <p>{`We allow users to authenticate with encrypted "magic links" sent directly to
their emails over SSL, or directly through one of our login/signup providers
(Xero, Google)`}</p>
    <p><strong parentName="p">{`Payments`}</strong></p>
    <p>{`Canyou uses PCI compliant payment processors when handling credit card
transactions. No credit card information is stored or is passed through our
services.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "privacy-and-personal-information"
    }}>{`Privacy and personal information`}</h2>
    <p><strong parentName="p">{`What does Canyou do with the personal information of its users?`}</strong></p>
    <p>{`We store user personal data in accordance with Australian regulations (Privacy Act 1988). Canyou does not sell any data to any third parties or affiliates.
Information submitted on the platform is available to the administrators of the relevant organisation. The Canyou Privacy Policy can be found `}<a parentName="p" {...{
        "href": "/privacy-policy/"
      }}>{`here`}</a>{`. `}</p>
    <hr></hr>
    <h2 {...{
      "id": "data"
    }}>{`Data`}</h2>
    <p><strong parentName="p">{`How long will my data be stored?`}</strong></p>
    <p>{`Whilst an organisation has a valid Canyou subscription they will have access to all their data in perpetuity. If an
organisations subscription has expired they can re-subscribe at any time to gain access to their account.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "accounts"
    }}>{`Accounts`}</h2>
    <p><strong parentName="p">{`How do I close an organisations account?`}</strong></p>
    <p>{`Whenever an organisation wishes to close their account they can send a request for us to do so at `}<a parentName="p" {...{
        "href": "mailto:support@canyou.com.au."
      }}>{`support@canyou.com.au.`}</a>{` `}</p>
    <p><strong parentName="p">{`How do I close an personal account?`}</strong></p>
    <p>{`Whenever a user wishes to close their personal account they can send a request for us to do so at `}<a parentName="p" {...{
        "href": "mailto:support@canyou.com.au."
      }}>{`support@canyou.com.au.`}</a>{` Canyou will delete the
personal account however this will not delete any information that is held by the organisation in which it was submitted to. To have this data deleted
the request must be made to the relevant organisation. The organisation can then send a request to `}<a parentName="p" {...{
        "href": "mailto:support@canyou.com.au"
      }}>{`support@canyou.com.au`}</a>{` to have the information permanently deleted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      